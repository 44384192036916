<template>
  <div>
    <h4
      style="
        margin: auto;
        width: 50%;
        font-weight: bold;
        text-align: center;
        font-size: 20px;
      "
    >
      LIST ITEM FREE TEXT FPB PROJECT
      <v-tooltip bottom color="grey">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            x-small
            text
            icon
            color="grey darken-2"
            @click="getDataFromApi"
          >
            <v-icon small>mdi-refresh</v-icon>
          </v-btn>
        </template>
        <span>refresh page</span>
      </v-tooltip>
    </h4>
    <v-card
      style="
        margin-top: 20px;
        margin-right: 10px;
        margin-left: 10px;
        margin-bottom: 30px;
        padding: 10px;
      "
    >
      <v-row no-gutters>
        <v-col cols="12">
          <div
            style="border-radius: 10px 10px 0 0"
            :class="`d-flex justify-start ${
              wWidth < 769 ? 'flex-column' : 'flex-row'
            }`"
          >
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <div
                v-if="
                  getUserProfile.level.find(
                    ({ id }) => id === '1' || id === '3'
                  ) !== undefined
                "
              >
                <v-btn
                  v-if="wWidth > 768"
                  outlined
                  small
                  elevation="1"
                  color="indigo"
                  class="indigo--text"
                  style="font-size: 12px; margin-right: 40px"
                  @click="exportData"
                >
                  Export Data
                </v-btn>
              </div>
            </div>
            <div class="d-flex" style="position: relative; top: 15px">
              <v-text-field
                v-model="paramAPI.keyword"
                @keyup.enter="searchEnter"
                label="Search here"
                type="search"
                outlined
                dense
                append-icon="mdi-magnify"
              >
              </v-text-field>
              <div>
                <v-select
                  label="Status"
                  style="
                    position: relative;
                    margin-left: 10px;
                    font-size: 12px;
                    height: 40px;
                  "
                  v-model="paramAPI.status"
                  :items="[
                    { id: '', name: 'All' },
                    { id: 0, name: 'Draft' },
                    { id: 1, name: 'Waiting' },
                    { id: 2, name: 'In Progress' },
                    { id: 3, name: 'Close' },
                    { id: 4, name: 'Cancel' },
                    { id: -1, name: 'Reject' }
                  ]"
                  item-text="name"
                  item-value="id"
                  outlined
                  return-id
                  dense
                ></v-select>
              </div>
              <v-select
                v-model="paramAPI.project_id"
                label="Cari Project"
                style="
                  position: relative;
                  margin: 0 10px;
                  font-size: 12px;
                  height: 40px;
                  width: 210px;
                "
                :items="dropdownProject"
                item-text="name"
                item-value="id"
                outlined
                return-id
                dense
                clearable
                @change="projectWatcher"
              >
              </v-select>
            </div>
          </div>
        </v-col>
        <v-col cols="12">
          <div class="d-flex justify-start align-center">
            <div
              style="
                height: 42px;
                margin-top: 5px;
                margin-bottom: 15px;
                margin-right: 10px;
                width: 210px;
              "
            >
              <v-autocomplete
                v-if="
                  getUserProfile.level.find(({ id }) => id === '41') !==
                  undefined
                "
                label="- Cari di Plant -"
                v-model="paramAPI.company_id"
                :items="dropdownCompany"
                @change="changeCompany"
                item-text="name"
                item-value="id"
                return-id
                outlined
                dense
                style="margin: 0; height: 42px"
                clearable
              ></v-autocomplete>
              <v-autocomplete
                v-else
                label="- Cari di Plant -"
                v-model="paramAPI.company_id"
                :items="dropdownCompany"
                @change="changeCompany"
                item-text="name"
                item-value="id"
                return-id
                outlined
                dense
                style="margin: 0; height: 42px"
                :disabled="isDisableCompanyDropdown"
                clearable
              ></v-autocomplete>
            </div>
            <div
              v-if="wWidth > 768"
              style="
                height: 42px;
                margin-top: 5px;
                margin-bottom: 15px;
                margin-left: 10px;
                width: 210px;
              "
            >
              <v-select
                label="- Cari Kategori -"
                v-model="paramAPI.category_item"
                :items="dropdownCategoryItem"
                @change="changeCategory"
                item-text="name"
                item-value="id"
                return-id
                outlined
                dense
                style="margin: 0; height: 42px"
                clearable
              ></v-select>
            </div>

            <div
              v-if="wWidth > 768"
              style="
                height: 42px;
                margin-top: 5px;
                margin-bottom: 15px;
                margin-left: 10px;
              "
              class="d-flex"
            >
              <v-text-field
                class="ml-1 mr-2"
                dense
                label="Start"
                type="date"
                name="startdate"
                style="height: 30px"
                step="1"
                v-model="paramAPI.request_date_start"
                @change="startDateWatcher"
                outlined
                clearable
              />

              <v-text-field
                class="ml-1 mr-2"
                dense
                label="End"
                type="date"
                name="enddate"
                style="height: 30px"
                step="1"
                v-model="paramAPI.request_date_end"
                @change="endDateWatcher"
                outlined
                clearable
              />
            </div>
            <!-- </div> -->
          </div>
        </v-col>
        <v-col
          cols="12"
          v-if="wWidth < 769"
          style="margin-bottom: 20px"
          class="d-flex"
          ><v-text-field
            class="mr-2"
            dense
            label="Start"
            type="date"
            name="startdate"
            style="height: 30px"
            step="1"
            v-model="paramAPI.request_date_start"
            @change="startDateWatcher"
            outlined
            clearable
          />
          <v-text-field
            class="ml-1"
            dense
            label="End"
            type="date"
            name="enddate"
            style="height: 30px"
            step="1"
            v-model="paramAPI.request_date_end"
            @change="endDateWatcher"
            outlined
            clearable
          />
        </v-col>
        <v-col cols="12" v-if="wWidth < 769" style="margin-bottom: 13px">
          <v-btn
            v-if="
              getUserProfile.level.find(
                ({ id }) => id === '1' || id === '3'
              ) !== undefined
            "
            small
            elevation="1"
            color="indigo"
            class="white--text"
            style="font-size: 12px; width: 100%; height: 40px"
            @click="exportData"
          >
            Export Data
          </v-btn>
        </v-col>

        <v-col cols="12">
          <v-data-table
            style="cursor: pointer"
            @click:row="rowClick"
            mobile-breakpoint="0"
            height="50vh"
            class="elevation-1"
            :headers="headers"
            :items="result"
            :footer-props="{
              showFirstLastPage: true,
              showCurrentPage: true,
              itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
            }"
            :server-items-length="totalData"
            :page="paramAPI.page"
            :items-per-page="paramAPI.itemsPerPage"
            @update:page="updatePage"
            @update:items-per-page="updateItemPerPage"
          >
            <template v-slot:[`item.item.fulfillment_status`]="{ item }">
              <!-- <div
                :style="`width: 125px; font-size: 12px; color:${
                  item.fulfillment_status == 1 ? 'green' : 'red'
                }`"
              >
                {{
                  item.fulfillment_status == 1
                    ? 'Sudah lengkap'
                    : 'Belum lengkap'
                }}
              </div> -->
              <div @click.stop style="width: 75px; height: 25px">
                <v-checkbox
                  @change="updateFulfillmentStatus(item.item)"
                  v-model="item.item.fulfillment_status"
                  :true-value="1"
                  :false-value="0"
                  :color="item.item.fulfillment_status == 1 ? 'green' : ''"
                  style="margin: 0 0 0 10px; height: 25px; padding: 0"
                  :disabled="
                    getUserProfile.level.find(({ id }) => id === '3') ===
                      undefined && getUserProfile.employee.company.plant_id != 6
                  "
                ></v-checkbox>
              </div>
            </template>
            <template v-slot:[`item.request_no`]="{ item }">
              <div style="font-size: 13px; font-weight: bolder">
                {{ item.request_no }}
              </div>
            </template>
            <template v-slot:[`item.employee`]="{ item }">
              <div style="width: 125px; font-size: 12px">
                {{ item.employee.name }}
              </div>
            </template>
            <template v-slot:[`item.company`]="{ item }">
              <div style="width: 125px; font-size: 12px">
                {{ item.company.name }}
              </div>
            </template>
            <template v-slot:[`item.project.name`]="{ item }">
              <div style="width: 75px; font-size: 12px">
                {{ item.project.name }}
              </div>
            </template>
            <template v-slot:[`item.item.product_name`]="{ item }">
              <div style="width: 75px; font-size: 12px">
                {{ item.item.product_name }}
              </div>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <div
                :style="`color: ${colorStatus(
                  item.status
                )}; width: 75px; font-size:13px; font-weight:bolder;`"
              >
                {{ status(item.status) }}
              </div>
            </template>
            <template v-slot:[`item.item.status`]="{ item }">
              <div style="width: 75px">
                <div v-if="item.item.status === 0" @click.prevent>
                  <td
                    style="
                      border: 0.5px solid rga(0, 0, 0, 0.8);
                      font-size: 12px;
                      border-radius: 50%;
                      height: 22px;
                      width: 22px;
                      color: white;
                      font-weight: bold;
                      background-color: rgba(0, 0, 0, 0.8);
                    "
                    class="d-flex justify-center align-center"
                  >
                    D
                  </td>
                </div>
                <div bottom v-if="item.item.status === 1" @click.prevent>
                  <td
                    style="
                      border: 0.5px solid orange;
                      font-size: 12px;
                      border-radius: 50%;
                      height: 22px;
                      width: 22px;
                      color: white;
                      font-weight: bold;
                      background-color: orange;
                    "
                    class="d-flex justify-center align-center"
                  >
                    W
                  </td>
                </div>
                <div
                  class="d-flex"
                  v-if="item.item.status === 2"
                  @click.prevent
                >
                  <div
                    style="
                      border: 0.5px solid blue;
                      font-size: 12px;
                      border-radius: 50%;
                      height: 22px;
                      width: 22px;
                      color: white;
                      font-weight: bold;
                      background-color: blue;
                    "
                    class="d-flex justify-center align-center"
                  >
                    PR
                  </div>
                  <td
                    v-if="item.item.is_po_created"
                    style="
                      margin-left: 5px;
                      border: 0.5px solid blue;
                      font-size: 12px;
                      border-radius: 50%;
                      height: 22px;
                      width: 22px;
                      color: white;
                      font-weight: bold;
                      background-color: blue;
                    "
                    class="d-flex justify-center align-center"
                  >
                    PO
                  </td>
                </div>
                <div
                  v-if="item.item.status === 3"
                  style="
                    border: 0.5px solid blue;
                    font-size: 12px;
                    border-radius: 50%;
                    height: 22px;
                    width: 22px;
                    color: white;
                    font-weight: bold;
                    background-color: blue;
                  "
                  class="d-flex justify-center align-center"
                >
                  CA
                </div>
                <div
                  v-if="item.item.status === 4"
                  style="
                    border: 0.5px solid green;
                    font-size: 12px;
                    border-radius: 50%;
                    height: 22px;
                    width: 22px;
                    color: white;
                    font-weight: bold;
                    background-color: green;
                  "
                  class="d-flex justify-center align-center"
                >
                  CL
                </div>

                <td
                  v-if="item.item.status === -1"
                  style="
                    border: 0.5px solid red;
                    font-size: 12px;
                    border-radius: 50%;
                    height: 22px;
                    width: 22px;
                    color: white;
                    font-weight: bold;
                    background-color: red;
                  "
                  class="d-flex justify-center align-center"
                >
                  R
                </td>

                <div
                  bottom
                  v-if="item.item.status === 5"
                  style="
                    border: 0.5px solid red;
                    font-size: 12px;
                    border-radius: 50%;
                    height: 22px;
                    width: 22px;
                    color: white;
                    font-weight: bold;
                    background-color: red;
                  "
                  class="d-flex justify-center align-center"
                >
                  C
                </div>
              </div>
            </template>
            <template v-slot:[`item.product_code`]="{ item }">
              <div style="width: 75px; font-size: 12px">
                {{
                  item.item.product_code !== null ? item.item.product_code : '-'
                }}
              </div>
            </template>
            <template v-slot:[`item.item.estimate_fulfillment`]="{ item }">
              <div style="width: 75px; font-size: 12px">
                {{
                  convertDate(
                    item.item.estimate_fulfillment,
                    'toLocaleDateString'
                  )
                }}
              </div>
            </template>
            <template v-slot:[`item.created_at`]="{ item }">
              <div style="width: 75px; font-size: 12px">
                {{ item.created_at }}
              </div>
            </template>

            <!-- <template v-slot:[`item.actions`]>
              <div
                @click.stop
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                "
              >
                <v-btn color="success" class="ma-1 white--text" x-small>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" x-small class="">
                        mdi-check
                      </v-icon>
                    </template>
                    <span>Update lengkap</span>
                  </v-tooltip>
                </v-btn>
                <v-btn color="error" class="ma-1 white--text" x-small>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" x-small class="">
                        mdi-close
                      </v-icon>
                    </template>
                    <span>Update belum lengkap</span>
                  </v-tooltip>
                </v-btn>
              </div>
            </template> -->
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>

    <div style="position: fixed; bottom: 10px; right: 0">
      <Scanner />
    </div>
    <FpbCreate @loadPage="initDataTable" :wWidth="wWidth" />
  </div>
</template>
<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import buildType from '../../../services/buildType'
import * as XLSX from 'xlsx/xlsx.mjs'
import moment from 'moment'
export default {
  components: {
    FpbCreate: () => import('../../../components/eCatalogue/cart/FpbCreate'),
    Scanner: () => import('../../home/components/Scanner.vue')
  },
  data: () => ({
    wWidth: window.innerWidth,
    proc: buildType.apiURL('proc'),
    eSanqua: buildType.apiURL('esanqua'),
    hrsApi: buildType.apiURL('hrs'),
    paramAPI: {
      page: 1,
      keyword: '',
      offset: 0,
      limit: 10,
      status: '',
      length: 0,
      sortBy: 'request_no',
      sortType: 'desc',
      itemsPerPage: 10,
      company_id: null,
      department_id: null,
      category_item: null,
      request_date_start: '',
      request_date_end: '',
      project_id: null
    },
    totalData: 0,
    headers: [
      {
        text: 'No. Request',
        value: 'request_no',
        align: 'left',
        sortable: false
      },
      {
        text: 'PIC',
        value: 'employee',
        align: 'left',
        sortable: false
      },
      {
        text: 'Company',
        value: 'company',
        align: 'left',
        sortable: false
      },
      // {
      //   text: 'Kode produk',
      //   value: 'item.product_code',
      //   align: 'left',
      //   sortable: false
      // },
      {
        text: 'Project',
        value: 'project.name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Nama produk',
        value: 'item.product_name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Kode produk',
        value: 'product_code',
        align: 'left',
        sortable: false
      },
      {
        text: 'Tgl. buat fpb',
        value: 'created_at',
        align: 'left',
        sortable: false
      },
      {
        text: 'Estimasi dilengkapi',
        value: 'item.estimate_fulfillment',
        align: 'left',
        sortable: false
      },
      {
        text: 'Status FPB',
        value: 'status',
        align: 'left',
        sortable: false
      },
      {
        text: 'Status Item',
        value: 'item.status',
        align: 'left',
        sortable: false
      },
      {
        text: 'Status Kelengkapan',
        value: 'item.fulfillment_status',
        align: 'left',
        sortable: false
      }
      // {
      //   text: 'Action',
      //   value: 'actions',
      //   align: 'left',
      //   sortable: false
      // }
    ],
    result: [],
    detail: null,
    selected: null,
    loading: true,
    dropdownCompany: [],
    dropdownDepartment: [],
    dropdownProject: [],
    isDisableCompanyDropdown: false,
    url: ''
  }),
  watch: {
    'paramAPI.sortBy'() {
      this.getDataFromApi()
    },
    'paramAPI.sortType'() {
      this.getDataFromApi()
    },
    'paramAPI.status'() {
      this.getDataFromApi()
    }
  },
  computed: {
    ...mapGetters(['getUserProfile']),
    dropdownCategoryItem() {
      const arr = [
        { id: 1, name: 'Raw Material' },
        { id: 2, name: 'Factory Supply' },
        { id: 3, name: 'Office Supply' },
        { id: 4, name: 'Sparepart' },
        { id: 5, name: 'Jasa' },
        { id: 6, name: 'Maintenance Repair' },
        { id: 7, name: 'Investment / Asset' }
      ]
      if (this.paramAPI.company_id == 5 || this.paramAPI.company_id == 14) {
        arr.splice(
          2,
          4,
          {
            id: 8,
            name: 'Office Supply (< 1jt)'
          },
          {
            id: 9,
            name: 'Office Supply (1jt-5jt)'
          },
          {
            id: 10,
            name: 'Office Supply (> 5j)'
          },
          {
            id: 11,
            name: 'Marketing Material (< 1jt)'
          },
          {
            id: 12,
            name: 'Marketing Material (1jt-5jt)'
          },
          {
            id: 13,
            name: 'Marketing Material (> 5j)'
          },
          {
            id: 14,
            name: 'Sparepart (< 1jt)'
          },
          {
            id: 15,
            name: 'Sparepart (1jt-5jt)'
          },
          {
            id: 16,
            name: 'Sparepart (> 5j)'
          },
          {
            id: 17,
            name: 'Jasa (< 1jt)'
          },
          {
            id: 18,
            name: 'Jasa (1jt-5jt)'
          },
          {
            id: 19,
            name: 'Jasa (> 5j)'
          },
          {
            id: 20,
            name: 'Maintenance Repair (< 1jt)'
          },
          {
            id: 21,
            name: 'Maintenance Repair (1jt-5jt)'
          },
          {
            id: 22,
            name: 'Maintenance Repair (> 5j)'
          }
        )
      }
      return arr
    }
    // 'getFpbParam'
  },
  async mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    this.paramAPI.company_id = Number(
      this.getUserProfile.employee.company.plant_id
    )
    // if (this.getFpbParam != null) {
    //   this.paramAPI = this.getFpbParam
    // }
    setTimeout(async () => {
      await this.getDataFromApi()
      await this.initDropdown()
    }, 200)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel, pInputType, pInputAtt) {
      const title =
        pInputType === 'file'
          ? 'Upload bukti approvement pada kolom dibawah'
          : ''
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            // html: pStatusMsg,
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No',
            input: pInputType !== undefined ? pInputType : null,
            inputLabel: pInputType !== undefined ? title : null,
            inputAttributes: pInputAtt !== undefined ? pInputAtt : null
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    async updateFulfillmentStatus(item) {
      console.log('Update>>>>>', item)
      await axios
        .post(`${this.proc}fpb/project/update_fulfillment`, {
          id: item.id,
          fulfillment_status: item.fulfillment_status
        })
        .then((res) => {
          if (res.data.status_code == '-99') {
            this.showMsgDialog('warning', res.data.status_msg, false)
          }
          return null
        })
        .catch((err) => {
          console.log(err)
          return null
        })
    },
    onResize() {
      this.wWidth = window.innerWidth
    },
    addItem() {
      // this.initDropdown()
      setTimeout(() => {
        document.getElementById('fpbFormCreate').click()
      }, 500)
    },
    rowClick(pValue, pSlot) {
      const arrItem = {
        list: []
      }
      this.$store.commit('setFpbList', arrItem)
      setTimeout(() => {
        this.$router.push(
          `/e-catalogue/fpb-list/fpb/${pValue.id}?idx=${pSlot.index}`
        )
      }, 200)
    },
    async changeCompany(p) {
      this.paramAPI.company_id = p

      this.clearPaging()
      await this.getDataFromApi()
      await this.getDropdownDepartment()
      await this.getDropdownProject()
    },
    async changeDepartment(p) {
      this.paramAPI.department_id = p
      this.clearPaging()
      await this.getDataFromApi()
    },
    async changeCategory(p) {
      this.paramAPI.category_item = p
      this.clearPaging()
      await this.getDataFromApi()
    },
    startDateWatcher() {
      this.clearPaging()
      this.getDataFromApi()
    },
    endDateWatcher() {
      this.clearPaging()
      this.getDataFromApi()
    },
    projectWatcher() {
      this.clearPaging()
      this.getDataFromApi()
    },
    async initDropdown() {
      await this.getDropdownCompany()
      await this.getDropdownDepartment()
      this.getDropdownProject()
    },
    async getDropdownCompany() {
      const level = this.getUserProfile.level.find(
        ({ id }) =>
          id === '3' || id === '14' || id === '23' || id === '27' || id === '41'
      )
      if (level !== undefined) {
        if (level.user_user_level !== null) {
          if (
            level.user_user_level.allowed_company === null ||
            level.user_user_level.allowed_company.length === 0
          ) {
            this.dropdownCompanyApi()
          } else {
            this.dropdownCompany = level.user_user_level.allowed_company
            this.isDisableCompanyDropdown = false
          }
        } else {
          this.dropdownCompanyApi()
        }
      } else {
        this.dropdownCompanyApi()
      }
    },
    async dropdownCompanyApi() {
      if (Number(this.getUserProfile.employee.company.plant_id) !== 6) {
        this.isDisableCompanyDropdown = true
      } else {
        this.isDisableCompanyDropdown = false
      }
      axios
        .get(`${this.eSanqua}master/universal/plant/dropdown`)
        .then((res) => {
          this.dropdownCompany = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    async getDropdownDepartment() {
      await axios
        .get(
          `${this.hrsApi}master/universal/department/dropdown?filter=[{"company_id":${this.paramAPI.company_id}}]`
        )
        .then((res) => {
          console.log(res)
          if (res.data.status_code === '00') {
            return (this.dropdownDepartment = res.data.data)
          }
          return (this.dropdownDepartment = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdownDepartment = [])
        })
    },
    async getDropdownProject() {
      let url = `${this.proc}globalmaster/project/dropdown?keyword=`
      if (this.paramAPI.company_id !== null) {
        url = url + `&filter=[{"company_id":${this.paramAPI.company_id}}]`
      }
      console.log(url)
      axios
        .get(url)
        .then((res) => {
          this.dropdownProject = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    async getDataFromApi() {
      this.loading = true
      await this.initDataTable()
        .then((data) => {
          if (data.status_code === '00') {
            this.totalData = Number(data.total_record)
            return (this.result = data.data)
          } else {
            this.totalData = 0
            return (this.result = [])
          }
          // return this.showMsgDialog('warning', data.status_msg, 'false')
        })
        .catch((err) => {
          console.log(err)
          return (this.result = [])
        })
      this.loading = false
    },
    initDataTable() {
      // http://localhost:9191/api/procurement/v1/
      let url = `${this.proc}fpb/project/list?keyword=${
        this.paramAPI.keyword
      }&offset=${this.paramAPI.offset * this.paramAPI.limit}&limit=${
        this.paramAPI.limit
      }&order_by=${this.paramAPI.sortBy}&order_type=${
        this.paramAPI.sortType
      }&status=${this.paramAPI.status}`

      if (this.paramAPI.company_id !== null) {
        url = url + `&company_id=${this.paramAPI.company_id}`
      }
      if (this.paramAPI.department_id !== null) {
        url = url + `&department_id=${this.paramAPI.department_id}`
      } else {
        url = url + '&department_id='
      }
      if (this.paramAPI.category_item !== null) {
        url = url + `&category_item=${this.paramAPI.category_item}`
      }
      if (this.paramAPI.request_date_start !== null) {
        url = url + `&request_date_start=${this.paramAPI.request_date_start}`
      }
      // else {
      //   url = url + "&request_date_start=''"
      // }
      if (this.paramAPI.request_date_end !== null) {
        url = url + `&request_date_end=${this.paramAPI.request_date_end}`
      }
      //  else {
      //   url = url + "&request_date_end=''"
      // }
      if (this.paramAPI.project_id !== null) {
        url = url + `&project_id=${this.paramAPI.project_id}`
      }
      this.url = url
      this.$store.commit('setFpbItems', this.paramAPI)
      return new Promise((resolve, reject) => {
        axios
          .get(url)
          .then((res) => {
            console.log(res)
            resolve(res.data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    async updatePage(p) {
      this.paramAPI.page = p
      this.paramAPI.offset = p - 1
      await this.getDataFromApi()
    },
    async updateItemPerPage(p) {
      this.paramAPI.itemsPerPage = p
      this.paramAPI.limit = p
      this.paramAPI.offset = 0
      await this.getDataFromApi()
    },
    async searchEnter() {
      await this.getDataFromApi()
    },
    clearPaging() {
      this.paramAPI.page = 1
      this.paramAPI.offset = 0
      this.paramAPI.limit = 10
      this.paramAPI.itemsPerPage = 10
    },
    status(val) {
      switch (val.id) {
        case -1:
          return 'Rejected'
        case 0:
          return 'Draft'
        case 1:
          return 'Waiting Approval'
        case 2:
          return 'In Progress'
        case 3:
          return 'Close'
        case 4:
          return 'Cancel'
        case 5:
          return 'Pending'
      }
    },
    colorStatus(val) {
      switch (val.id) {
        case -1:
          return 'red'
        case 0:
          return '#1976d2'
        case 1:
          return 'orange'
        case 2:
          return 'green'
        case 3:
          return '#4CAF50'
        case 4:
          return 'red'
        case 5:
          return 'black'
      }
    },
    async exportData() {
      // let url = `${this.proc}fpb/list?keyword=${this.paramAPI.keyword}&offset=0&limit=all&order_by=request_no&order_type=asc&status=&department_id=&request_date_start=&request_date_end=`
      // if (this.paramAPI.company_id !== null) {
      //   url = url + `&company_id=${this.paramAPI.company_id}`
      // }
      const url = this.url + '&is_export=true'
      await axios
        .get(url)
        .then((res) => {
          console.log(res)
          let selectedData = []
          const response = res.data.data
          if (response !== undefined) {
            for (let i = 0; i < response.length; i++) {
              selectedData.push({
                request_no: response[i].request_no,
                employee: response[i].employee.name,
                department: response[i].department.name,
                company: response[i].company.name,
                submit_date: response[i].requested_at,
                status: response[i].status.name,
                created_at: response[i].created_at,
                item: response[i].item
              })
            }
            this.exportNow(selectedData)
          } else {
            selectedData = []
          }
          return null
        })
        .catch((error) => {
          console.log(error)
          return null
        })
    },
    exportNow(selectedData) {
      const arrData = []
      for (let i = 0; i < selectedData.length; i++) {
        const param = {
          request_no: selectedData[i].request_no,
          employee: selectedData[i].employee,
          department: selectedData[i].department,
          company: selectedData[i].company,
          submit_date: selectedData[i].submit_date,
          created_at: selectedData[i].created_at,
          status_fpb: selectedData[i].status,
          product_code: selectedData[i].item.product_code,
          product_name: selectedData[i].item.product_name,
          qty: selectedData[i].item.qty,
          uom: selectedData[i].item.uom_name,
          quotation_per_unit: selectedData[i].item.quotation_price_per_unit,
          budget_per_unit: selectedData[i].item.budget_price_per_unit,
          budget_total: selectedData[i].item.budget_price_total,
          last_price: selectedData[i].item.last_price,
          pr_no: selectedData[i].item.pr_no,
          status_item: selectedData[i].item.status,
          status_item_name: selectedData[i].item.status_name,
          is_po_created: selectedData[i].item.is_po_created,
          date_use: selectedData[i].item.estimate_date_use,
          estimate_fulfillment: selectedData[i].item.estimate_fulfillment,
          fulfillment_status: selectedData[i].item.fulfillment_status,
          fulfillment_status_name: selectedData[i].item.fulfillment_status_name,
          description: selectedData[i].item.description
        }
        arrData.push(param)
      }
      this.downloadExcell(arrData)
    },
    downloadExcell(arrData) {
      const data = XLSX.utils.json_to_sheet(arrData)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, data, 'data')
      const date = new Date()
      XLSX.writeFile(wb, `FPB_${date.toLocaleDateString('id')}.xlsx`)
    },
    price(pItem) {
      if (pItem !== undefined) {
        if (pItem !== null) {
          return pItem.toString().replace(/\B(?<!\\d*)(?=(\d{3})+(?!\d))/g, ',')
        }
      }
      return ''
    },
    convertDate(val, str) {
      const raw = val
      if (raw != null && raw != '') {
        // const date = new Date(raw)
        const local = moment(val).format('DD-MM-YYYY')
        return local
      }
    }

    // itemRowBackground(item) {
    //   if (item.item.fulfillment_status == 0) {
    //     return 'green'
    //   } else {
    //     return 'red'
    //   }
    // }
  }
}
</script>

<style lang="scss" scoped>
.vendorSortIcon {
  margin: 0;
  border: 1px solid rgba(0, 0, 0, 0.486);
  padding: 2px 5px;
  border-radius: 3px;
  cursor: pointer;
}
</style>
